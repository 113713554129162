/* You can add global styles to this file, and also import other style files */
$colorTextLight: #ffffff;

body {
  display: block;
  margin: 0px;
  font-size: 14px;
  font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.card-box {
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
  background-color: #fff;
  border-radius: 4px;
  padding: 30px !important;
  margin: 25px;
}

.k-checkbox-md {
  height: 16px;
  width: 16px;
}

.k-tabstrip-items {
  padding-top: 25px;
  padding-bottom: 1px;
}

.k-tabstrip-items .k-item.k-state-active {
  color: #c90b51;
}

.k-tabstrip-items .k-item {
  border: 0px !important;

}

.k-tabstrip-content,
.k-tabstrip>.k-content.k-state-active {
  border-color: rgba(0, 0, 0, 0.42);
  color: #424242;
  // background-color: #f2f2f2;
  color: #c90b51;
}

.mb-4 {
  margin: 1rem !important;
  max-width: 25%;
  flex: 0 0 25%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.k-floating-label-container {
  width: 100%;
  flex-direction: column-reverse !important;
}

.k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-items-wrapper .k-item.k-active,
.k-tabstrip-items-wrapper .k-item.k-selected {
  color: #c90b51
}

.k-link,
.k-link:hover {
  color: #0000
}

.k-button[_ngcontent-whw-c5] {
  border-radius: 2px;
  padding: 8px 16px;
  margin-right: 55px;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  font-size: 14px;
  line-height: 1.4285714286;
  font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;

  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  position: relative;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  font-weight: 500;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
}

.k-checkbox {
  border-color: rgba(0, 0, 0, 1);
  background-color: #ffffff;
  height: 16px;
  width: 16px;
  border-radius: 4px;
}

.k-checkbox-label {
  padding-top: 25px;
}

.k-picker-solid {
  border-width: 0 !important;
  border-color: #fff !important;
  border-bottom: 1px solid rgba(0, 0, 0, .42) !important;
  min-height: 36px;
  border-radius: 0;
  padding: 0;
  width: 100%;
  border-style: solid;
  box-sizing: border-box;
  // cursor: default;
  outline: 0;
  background-color: #ffffff;
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.0));
}

.k-tabstrip-top>.k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-top>.k-tabstrip-items-wrapper .k-item.k-active {
  margin-bottom: -1px;
  border-bottom-width: 7px;
  text-decoration: underline;
  text-underline-offset: 11px;
  border-bottom-color: #c90b51 !important;
}

.k-tabstrip-items .k-link {
  padding-block: 6px;
  padding-inline: 12px;
  color: #005aa6;
  font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;

  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
  flex: 1 1 auto;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.k-link,
.k-link:hover {
  color: #000;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
}

.k-link ng-star-inserted {
  color: #fff !important;
}

.k-grid-md .k-table-th>.k-cell-inner>.k-link {
  padding-block: 8px;
  padding-inline: 12px;
  color: white;
}

.k-tabstrip-items .k-link {
  color: #fff !important;
}

li>span .k-link ng-star-inserted {
  color: #fff !important;
}

.k-tabstrip-items-wrapper .k-item {
  color: #9c9b9b;
}

// .k-grouping-header > .k-grouping-drop-container {
//   flex-grow: 1;
//   padding-block: 8px;
//   margin-block: -8px;
//   display: inline-flex;
//   align-self: stretch;
//   align-items: center;
//   font-family: "SegoeUI","Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;

// }
div kendo-tabstrip {

  font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-style: #204486;
  // background-color: #f2f2f2;
  color: #fff;
}

.k-tabstrip {
  border-width: 0;
  border-color: transparent;
  box-sizing: border-box;
  outline: 0;
  font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;

  font-size: 14px;
  line-height: 1.4285714286;
  color: #c90b51;
  // background-color: #f2f2f2;
  display: flex;
  flex-flow: column nowrap;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.headerCustomClass {
  color: #fff !important;
}

.kendo-grid-pdf {
  align-items: right;
  justify-content: right;

}


div kendo-grid-column {
  text-transform: capitalize;
}

div kendo-grid-header-column {
  text-transform: capitalize;
}

div kendo-grid .k-grid-toolbar {
  padding: 8px 3px !important;
  border-width: 1px;
}

div kendo-grid {
  // width: calc(100% - 19px) !important;
  margin-left: 3px !important;
}

div k-grouping-header {
  //width: calc(100% - 19px) !important;
  margin-left: 3px !important;
}

div kendo-grid .k-grid-toolbar {
  padding: 8px 3px !important;
  border-width: 1px;
  justify-content: right;
}

.k-grid-toolbar {
  border-color: inherit;
  box-shadow: none;
  justify-content: right;
}

.k-toolbar {
  margin: 0;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;

  font-size: 14px;
  line-height: 1.4285714286;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: right;
  position: relative;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.k-grid .k-table-th,
.k-grid td,
.k-grid .k-table-td {
  border-width: 0;
  border-inline-start-width: 1px;
  border-style: solid;
  font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  outline: 0;
  font-weight: inherit;
  text-align: inherit;
  position: static;
  overflow: hidden;
  text-overflow: nowrap;
  box-sizing: content-box;
  word-wrap: break-word;
}

.nav-item {

  &.active {
    background-color: #005aa6;
    color: #0000;
  }

  &.hover {
    background-color: #e6d7d7;
    color: #0000;
  }
}

.k-tabstrip-items .k-link {
  color: #c90b51 !important;
}

.k-grid-md .k-table-th {
  background-color: #005AA6 !important;
}

.k-button-solid-primary {
  border-color: #c90b51;
  color: white;
  background-color: #c90b51;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}

.k-checkbox-label {
  padding-top: 0px;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: #c90b51;
  color: white;
  background-color: #c90b51;
}

.k-popup {
  margin: 0;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.4285714286;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 250px;
}

.k-button-solid-primary:hover,
.k-button-solid-primary.k-hover {
  border-color: #c90b51;
  background-color: #c90b51;
}

.textareawrapOnDeMand textarea.k-input-inner {
  margin: 0;
  height: 100px;
}

.textareawrap textarea.k-input-inner {
  margin: 0;
  height: 300px;
}

.textareawrap2 textarea.k-input-inner {
  margin: 0;
  height: 100px;
}

#textarea1 {
  margin: 0;
  height: 100px;
}

// For dialog title
.k-dialog-titlebar {
  border-color: inherit;
  color: #fff;
  background-color: cornflowerblue;
}

.textareawrapmsg textarea.k-input-inner {
  margin: 0;
  height: 100px;
}

.modal-close-button {
  width: 70px;
  margin: auto;
  flex: 0 0 0%;
}

/*****kendo dropdown class started*********/
// .k-dropdown-wrap>.k-input, .k-dropdown .k-dropdown-wrap .k-select, .k-list, .k-reset {
//   background-color: lightblue;
// }
.k-dropdown-wrap>.k-input,
.k-dropdown .k-dropdown-wrap .k-select,
.k-list {
  // background-color: lightblue;
  // width: 100%;
  // flex: 0 0 0%;
  font-size: 14px;
  font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

kendo-dropdownlist {
  width: 248px !important;
  // background-color: #fff;
  font-size: 14px;
  font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

kendo-multiselect {
  width: 248px !important;
  // background-color: #fff;
  font-size: 14px;
  font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

/*****kendo dropdown class ended*********/


.k-input-md,
.k-picker-md {
  font-size: 14px !important;
  font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  line-height: 1.4285714286;
}

/******Date time control css started**********/

.k-datetime-wrap .k-calendar,
.k-datetime-wrap .k-timeselector {
  font-size: 11px !important;
  border-width: 1;
}

.k-calendar-th {
  font-size: 12px !important;
}

.k-calendar-header .k-calendar-title {
  font-weight: bold;
  font-size: 12px;
}

.k-button-flat-primary {
  color: #ff6358;
  font-size: 12px;
}

.k-calendar-container,
.k-datetime-container {
  /* padding: 0; */
  width: 100% !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.k-datetime-footer {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

/******Date time control css ended**********/

.k-input-spinner {
  width: calc(1.4285714286em + 8px);
  flex: none;
  display: none !important;
  // flex-flow: column nowrap;
}

.textareawrap .textareawrap {
  margin: 0;
  height: 300px;
}

#textarea1 {
  margin: 0;
  height: 100px;
}

.k-grid .k-column-title {
  white-space: normal;
}


// .k-input {
// overflow: auto !important;
// }
kendo-grid-filter-menu-container {
  margin-left: -8px;
}

.click-link {
  cursor: pointer;
  transition: color .3s linear;
  color: #03a9f4;
  text-decoration-line: underline !important;
}

.k-expander-title {
  color: #fff;
}

.k-expander-header {
  color: #c1c1c1;
  outline: solid;
  background-color: #005AA6 !important;
}

.k-expander.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);

}



// @import './color-pallete.scss';
// @import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
// @import "~jsoneditor/dist/jsoneditor.min.css";

// body {
//   margin: 0px;
//   padding: 0px;
//   font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
//   // color: $colorText;
//   background-color: #f2f2f2;
//   font-weight: 600;
//   letter-spacing: 1px;
//   position: relative;
// }

// .container-fluid {
//   padding: 16px 30px;
// }

// .k-tabstrip-items .k-link {
//   padding: 8px 22px !important;
// }


// .k-radio-label {
//   // padding-left: 30px;
//   width: 100%;
// }

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//   -webkit-box-shadow: 0 0 0px 1000px #fff inset;
//   transition: background-color 5000s ease-in-out 0s;
// }

// .cdk-overlay-container {
//   z-index: 20000;
// }

// .widget-container {
//   box-shadow: none !important;
// }

// .k-numerictextbox {

//   .k-select {
//     visibility: hidden !important;

//   }

//   .k-numeric-wrap {
//     padding-top: 10px;
//     border-bottom: 1px solid #204486 !important;
//     color: #204486 !important;
//   }

//   .k-numerictextbox.readonly {
//     border-bottom: 1px solid #adadad !important;
//   }

// }

// .fabric-textbox {

//   .k-textbox {
//     margin-top: 10px;
//     font-size: 15px;
//     color: #204486;
//     border-bottom: 1px solid #adadad !important;
//     transition: initial !important;
//   }

//   .k-textbox.readonly {
//     border-bottom: 1px solid #adadad !important;
//     color: #333333 !important;
//   }
// }

// .dropdown-container kendo-dropdownlist.readonly {

//   .k-dropdown-wrap {}

//   .k-select {
//     visibility: hidden !important;

//   }

//   .k-input {
//     color: #204486 !important;

//   }

// }

// .dropdown-container kendo-dropdownlist .k-dropdown-wrap {
//   border-bottom: 1px solid #cccccc !important
// }

// .dropdown-container kendo-dropdownlist {



//   .dropdown.float {
//     font-weight: bold !important;
//   }

//   .floating-label-dropdown {
//     font-size: 12px !important;
//     font-weight: bold !important;
//     color: #333333 !important;
//   }
// }

// .dropdown-container .floating-label-dropdown.float {}



// .fabric-textbox {
//   label.float {
//     font-size: 12px !important;
//     font-weight: bold !important;
//     color: #333333 !important;
//   }

// }

// .k-column-chooser-title,
// .k-column-list-item {
//   padding-right: 0px;

// }

// .fabric-numeric-textbox .floating-label-numeric-textbox.float {
//   font-size: 12px !important;
//   font-weight: bold !important;
//   color: #333333 !important;
// }

// .fabric-numeric-textbox .k-numerictextbox.readonly .k-numeric-wrap {
//   border-bottom: 1px solid #e0e0e0 !important;

// }

// .k-dropdown-wrap .k-input {
//   color: #204486 !important;
// }

// .k-numeric-wrap .k-input {
//   color: #204486 !important;
// }

// .white-label {
//   .widget-container {
//     margin: 0px !important;
//   }
// }

// .card-box {
//   // @include card;
//   padding: 50px !important;
// }

// .right-aligned-header>.mat-content {
//   justify-content: space-between;
// }

// .right-aligned-header>.mat-content>mat-panel-title,
// .right-aligned-header>.mat-content>mat-panel-description {
//   flex: 0 0 auto;
// }

// .dynamic-form-section .mat-mdc-card {
//   padding: 5px;
//   margin: 1.2em;

// }

// .dynamic-form-section.mat-expansion-panel {
//   margin-bottom: 10px;
// }

// .dynamic-form-section mat-card .mat-icon {
//   cursor: pointer;
//   position: absolute;
//   top: 0;
//   right: 0;
// }

// .dynamic-form-section mat-card .mat-icon {
//   display: none;
// }

// .dynamic-form-section mat-card:hover .mat-icon {
//   display: block !important;
// }

// .mat-expansion-panel-header-description {
//   flex-grow: 2;
//   float: right;
//   flex: auto !important;
//   margin-right: 0px !important;
//   cursor: pointer;
// }

// .dynamic-form-section mat-card .mat-icon {
//   display: none;
// }

// .dynamic-form-section mat-card:hover .mat-icon {
//   display: block !important;
// }

// .mat-expansion-panel-header-description {
//   flex-grow: 2;
//   float: right;
//   flex: auto !important;
//   margin-right: 0px !important;
//   cursor: pointer;
// }

// .dynamic-form-section .mat-mdc-form-field {
//   width: 100%
// }

// .dynamic-form-container .aui_FieldLayout {
//   width: 100% !important;
// }

// .dynamic-form-container {
//   width: 100%;
//   margin-bottom: 10px;
// }

// .mat-mdc-dialog-container {
//   overflow: visible !important;
//   // height: 500px !important;
// }

// .dynamic-form-container .mat-expansion-panel {
//   margin-top: 15px !important;
// }

// .k-multiselect {
//   margin-top: 10px !important;
// }

// .k-multiselect-wrap {
//   border-bottom-color: darkblue !important;
// }

// .dynamic-form-title {
//   width: 100%
// }

// .dynamic-form-title mat-expansion-panel-header-title {
//   width: 100%;
// }

// .action-buttons fabric-button {
//   margin-right: 10px;
//   // padding: 20px 25px;
// }

// .fabric-loader {
//   &.accent {
//     position: fixed;
//     top: 50%
//   }
// }

// .click-link {
//   cursor: pointer;
//   transition: color 0.3s linear;
//   color: #03a9f4;
// }

// .fabric-grid-column.fabric-grid-header-column {
//   text-transform: capitalize;
// }

// .fabric-grid .k-grid-toolbar {
//   padding: 8px 3px !important;
//   border-width: 1px;
// }

// .fabric-grid .k-grouping-header {
//   width: calc(100% - 23px) !important;
//   margin-left: 3px !important;
// }

// .fabric-button {
//   text-transform: uppercase;
// }

// .white_pl {
//   padding-left: 60px;
// }

// .white_pll {
//   padding-left: 100px;
// }


// .fabric-button.width button {
//   width: 100%;
// }

// white-label-preview.preview {
//   float: left;
//   display: block;
//   width: 100%;
//   position: relative;
//   top: -10px;
// }



// .client-intake-form {

//   .checkboxpad div.col-md-2:first-child,
//   .checkboxpad div.col-md-2:nth-child(7) {
//     padding-left: 0 !important;
//     display: inline-table !important;
//   }

//   .radioPad div.col-md-2:first-child {
//     padding-left: 0 !important;
//   }

//   .checkboxpad div.col-md-2,
//   .radioPad div.col-md-2 {
//     margin-bottom: 10px;
//   }

//   .connectionRadio+div {
//     margin-top: 30px;
//   }

//   .connectionRadio+div .mb-3 {
//     margin-bottom: 30px !important;
//   }

//   .connectionRadio+div .mt-3 {
//     margin-top: 30px !important;
//   }

// }

// .fontBlue {
//   color: #005aa6;
// }


// .k-chart-surface {
//   margin-top: 30px;
// }

// .k-chart-surface svg {
//   overflow: visible !important;
// }

// @media only screen and (max-width: 574px) {
//   .dropdown-container {
//     margin-bottom: 20px;
//   }
// }

// @media only screen and (max-width: 768px) {
//   .dashboard .widget-container .col-md-6 {
//     margin-bottom: 20px;
//   }

//   .page-header,
//   .page-header.container-fluid {
//     padding-left: 18px !important;
//   }

//   .clientCha {
//     max-width: 33%;
//     flex: 0 0 33%;
//   }

//   .checkboxpad .col-md-2,
//   .radioPad .col-md-2,
//   admin-radio-group .col-md-2 {
//     max-width: 25%;
//     flex: 0 0 25%;
//   }

//   .claimlisting {
//     margin-right: 10px !important;
//   }

//   .alignButtons {
//     margin-left: -5px !important;
//   }

//   .dashboard .col-12.col-md-6.col-xl-6 {
//     margin-bottom: 30px;
//   }

//   .k-chart-surface {
//     margin-top: 0px;
//   }
// }



// @font-face {
//   font-family: SegoeUI;
//   src:
//     local("Segoe UI Semilight"),
//     url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2) format("woff2"),
//     url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff) format("woff"),
//     url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf) format("truetype");
// }

// .dashBoardChartTitleHeader {
//   padding-left: 34px;
//   padding-bottom: 10px;
//   padding-top: 10px;
//   color: #000;
//   margin-bottom: 30px !important;
//   background-color: #f2f2f2;
// }

// .dashBoardChartTitleHeader.font-weight-bold {
//   font-weight: bold !important;
// }

// .widget-container {
//   margin-top: 15px;
// }

// .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
//   background-color: #adadad !important;
// }

// .dropdown-container .floating-label-dropdown.float {
//   font-size: 14px !important;
//   -webkit-transform: translateY(-35px) !important;
//   transform: translateY(-35px) !important;
//   left: 0;
// }

// .dropdown-container kendo-dropdownlist .k-dropdown-wrap,
// .dropdown-container kendo-dropdownlist.readonly .k-dropdown-wrap.k-state-default {
//   border-bottom: 1px solid #adadad !important;
// }

// .dropdown-container .k-dropdown-wrap:focus {
//   border-bottom: 1px solid #005aa6 !important;
// }

// .dropdown-container .k-dropdown-wrap:hover,
// kendo-dropdownlist.readonly .k-dropdown-wrap.k-dropdown-wrap.k-state-focused,
// kendo-dropdownlist.readonly .k-dropdown-wrap:hover,
// .dropdown-container kendo-dropdownlist.readonly .k-dropdown-wrap.k-state-default:hover {
//   border-bottom: 1px solid #005aa6 !important;
// }

// .dashboard .dropdown-container .k-dropdown-wrap {
//   border-bottom: 1px solid #adadad !important;
// }

// .container-fluid .dropdown-container .k-dropdown-wrap:hover {
//   border-bottom: 1px solid #005aa6 !important;
// }

// .k-form .dropdown-container .k-dropdown-wrap>.k-input,
// .card-box .dropdown-container .k-dropdown-wrap>.k-input {
//   padding-left: 8px !important;
// }


// .k-grid-content.k-virtual-content {
//   // height: 33vh;
//   // max-height:100vh;
// }

// @media (min-height: 900px) {
//   .k-grid-content.k-virtual-content {
//     height: 40vh;
//   }
// }

// @media (min-height: 1080px) {
//   .k-grid-content.k-virtual-content {
//     height: 49vh;
//   }
// }

// @media (min-height: 1440px) {
//   .k-grid-content.k-virtual-content {
//     height: 59vh;
//   }
// }


// @media (min-height: 2160px) {
//   .k-grid-content.k-virtual-content {
//     height: 69vh;
//   }
// }

// @media (min-height: 4320px) {
//   .k-grid-content.k-virtual-content {
//     height: 84vh;
//   }
// }


// @media all and (-ms-high-contrast:none) {
//   .right.card div img+div {
//     padding: 0px 16px 16px !important;
//   }

//   .right.card div img {
//     height: 100px;
//   }

//   *::-ms-backdrop,
//   .right.card div img+div {
//     padding: 0px 16px 16px !important;
//   }

//   *::-ms-backdrop,
//   .right.card div img {
//     height: 100px;
//   }

//   .card-box {
//     width: 100%;
//   }

//   *::-ms-backdrop,
//   .card-box {
//     width: 100%;
//   }

//   .k-grid-header-wrap table colgroup col {
//     width: 145px !important;
//   }

//   *::-ms-backdrop,
//   .k-grid-header-wrap table colgroup col {
//     width: 145px !important;
//   }

//   .k-grouping-header-flex>.k-indicator-container {
//     padding: 18px 0px 18px 14px !important;
//   }

//   *::-ms-backdrop,
//   .k-grouping-header-flex>.k-indicator-container {
//     padding: 18px 0px 18px 14px !important;
//   }

//   .dashboard ccf-widget-container .widget-container {
//     max-height: 680px !important;
//   }

//   *::-ms-backdrop,
//   .dashboard ccf-widget-container .widget-container {
//     max-height: 680px !important;
//   }

//   .fabric-textbox .container-fluid .floating-label-fabric-textbox {
//     top: 10px !important;
//   }

//   *::-ms-backdrop,
//   .container-fluid .fabric-textbox .floating-label-fabric-textbox {
//     top: 10px !important;
//   }

//   .card-box {
//     padding: 50px !important;
//   }

//   *::-ms-backdrop,
//   .card-box {
//     padding: 50px !important;
//   }
// }

// .rightandLeftLogo .fabric-textbox .floating-label-fabric-textbox.float {
//   -webkit-transform: translateY(-35px) !important;
//   transform: translateY(-35px) !important;
// }

// .widget-container .dropdown-container .k-dropdown-wrap:hover {
//   border-bottom: 1px solid #005aa6 !important;
// }

// ccf-widget-container .widget-container {
//   max-height: 680px !important;
// }

// .dropdown-container .k-dropdown {
//   margin-top: 10px;
// }

// .fabric-textbox .k-textbox:not(:focus):hover,
// .txtbox:hover {
//   border-color: #005aa6 !important;
// }

// .dropdown-container .floating-label-dropdown {
//   left: 7px !important;
//   // margin-top:10px !important;
// }

// .dashboard .dropdown-container .floating-label-dropdown {
//   margin-top: 0px !important;
// }

// // input fields border

// .fabric-textbox .floating-label-fabric-textbox {
//   top: 20px !important;
//   left: 7px !important;
//   white-space: nowrap;
// }


// // .fabric-textbox .k-textbox, .txtbox {
// //   border: 1px solid #adadad !important;
// // }

// .fabric-textbox .floating-label-fabric-textbox.float {
//   transform: translateY(-30px)translateX(-7px) !important;
// }

// .fabric-textbox label.float {
//   font-size: 15px !important;
// }

// .mb-4,
// .mb-3,
// .mb-6 {
//   margin-bottom: 0rem !important;
// }

// .fabric-textbox {
//   height: 90px;
// }

// .dropdown-container {
//   height: 90px;
// }

// //client channel

// .checkboxpad {
//   margin-bottom: 30px !important;
// }

// .checkboxpad h5 {
//   margin-bottom: 15px !important;
// }

// .ml-4.header-title {
//   margin-top: 20px;
//   margin-left: 30px !important;
// }

// // white label site

// .white-label .card-box {
//   padding: 30px !important;
// }

// .white-label .col-md-3.mb-4 .tooltipPos {
//   //position: absolute;
//   //left: 62% !important;
//   top: 2px !important;
// }

// .col-md-12.label.p-3.mb-3.ml-1 {
//   margin-top: 20px;
//   margin-bottom: 30px !important;
// }

// .whiteLabelHeader .col-md-6.mb-3 {
//   padding-left: 20px;
// }

// .deskNavigation {
//   display: block;
// }

// .mobNavigation {
//   display: none;
// }

// .dashboard .dropdown-container {
//   height: 50px !important;
// }

// kendo-popup.k-animation-container {
//   //top: 300px!important;
//   padding-bottom: 0px !important;
//   // overflow: auto;
// }

// .grid-btns {
//   color: #fff;
//   background-color: #193971 !important;
//   margin-right: 16px;
// }

// // Responsive

// @media screen and (max-width: 1080px) {
//   .mobNavigation .nav-bar .logo {
//     text-align: left !important;
//     padding-top: 15px;
//     padding-left: 10px;
//   }
// }

// @media only screen and (max-width: 768px) {
//   .dashboard .widget-container .col-md-6 {
//     margin-bottom: 20px;
//   }

//   .k-animation-container {
//     width: 100%;
//     left: 20% !important;
//     top: 0;
//   }

//   .deskNavigation {
//     display: none !important;
//   }

//   .mobNavigation {
//     display: block !important;
//   }

//   .mobNavigation .overflow-button {
//     display: block !important;
//     padding: 10px 15px !important;
//     color: #000 !important;
//     border-bottom: 1px solid #adadad;
//   }

//   .mobNavigation .overflow-button:hover,
//   .mobNavigation .overflow-button:active {
//     background-color: #C91851;
//   }

//   .mobNavigation .nav-bar {
//     padding: 0px 0px !important;
//   }

//   .mobNavigation .nav-menu-template {
//     width: 100%;
//     margin-top: 20px;
//     background: #fff;
//   }

//   .moblinksShow {
//     display: block;
//   }

//   .moblinksHide {
//     display: none;
//   }

//   .mobNavigation .search-container {
//     background: #fff;
//     padding: 15px 0px;
//   }

//   .mobNavigation .nav-item ul li {
//     float: none !important;
//     text-align: right !important;
//     margin-right: 20px !important;
//   }

//   .mobNavigation .nav-item ul li ul {
//     right: 0 !important;
//     left: auto !important;
//     background: #adadad !important;
//   }

//   .mobNavigation .nav-item ul li ul li {
//     text-align: center !important;
//   }

//   .dashboard .col-12.col-md-6.col-xl-6 {
//     margin-bottom: 30px;
//   }

//   .k-chart-surface {
//     margin-top: 0px;
//   }

//   .dashboard ccf-widget-container .widget-container {
//     max-height: 100% !important;
//   }

//   .container-fluid {
//     padding: 5px 10px !important;
//   }
// }

// @media only screen and (max-width: 574px) {
//   .dropdown-container {
//     margin-bottom: 20px;
//   }

//   .dashboard .widget-container {
//     max-height: 100% !important;
//   }
// }

// .bottomButtonsList {
//   margin-right: 0px;
// }

// .mat-mdc-form-field-underline {
//   display: none;
// }

// .mat-mdc-form-field-infix .mat-input-element,
// .mat-select {
//   border-bottom: 1px solid #adadad;
//   padding-bottom: 5px;
// }

// .mat-mdc-form-field-infix .mat-input-element:focus,
// .mat-select:focus {
//   border-bottom: 1px solid #005aa6;
//   padding-bottom: 5px;
// }

// .formValid {
//   padding-left: 10px;
// }

// .formBuildName {
//   padding-left: 0px;
//   padding-top: 35px !important;
// }

// .k-gradient-values {
//   width: 395px;
// }

// .tooltiptext {
//   border: 1px solid #ccc;
//   font-family: SegoeUI !important;
//   font-size: 12px !important;
//   background: #f7f4f4 !important;
//   padding: 5px !important;
//   text-align: left !important;
//   margin-left: 10px;
// }

// .editIcon {
//   left: -17px;
//   position: absolute;
//   width: 87px;
//   top: 27px;
// }

// .hoverBut {
//   margin-left: -87px;
//   z-index: 1;
// }

// // .mat-button + mat-list-item .mat-icon {
// //   display:none;
// // }


// .editIconButs {
//   position: relative;
//   background: white;
//   float: right;
//   z-index: 2;
// }

// .angular-editor-button[title="Subscript"],
// .angular-editor-button[title="Italic"],
// .angular-editor-button[title="Underline"],
// .angular-editor-button[title="Superscript"],
// .angular-editor-button[title="Justify Left"],
// .angular-editor-button[title="Justify Right"],
// .angular-editor-button[title="Justify Full"],
// .angular-editor-button[title="Indent"],
// .angular-editor-button[title="Outdent"],
// .angular-editor-button[title="Unordered List"],
// .angular-editor-button[title="Ordered List"],
// .angular-editor-button[title="Underline"],
// .angular-editor-button[title="Text Color"],
// .angular-editor-button[title="Background Color"],
// .angular-editor-button[title="Insert Image"],
// .angular-editor-button[title="Insert Video"],
// .angular-editor-button[title="Horizontal Line"],
// .angular-editor-button[title="Strikethrough"],
// .select-heading,
// .select-font,
// .select-font-size,
// .select-custom-style {
//   display: none !important;
// }

// .jsoneditorContainer .fabric-window {
//   width: 818px !important;
//   height: 480px;
//   //top:3000px !important;
// }

// .jsoneditorContainer {
//   position: absolute;
//   top: 50%;
//   left: 50%
// }

// .jsoneditorContainer fabric-window {
//   position: relative !important;
//   left: -50%;
//   top: -50%;
// }

// .jsoneditorContainer .fabric-window.k-widget.k-window {
//   position: fixed !important;
//   top: 0px !important;
//   bottom: 0px !important;
//   width: 60.5% !important;
//   left: 270px !important;
//   height: 460px !important;
// }

// .jsoneditorContainer .k-window-content {
//   overflow: unset !important;
// }

// .editIconButs {
//   position: relative;
//   background: white;
//   float: right;
//   z-index: 2;
// }

// .angular-editor-button[title="Subscript"],
// .angular-editor-button[title="Italic"],
// .angular-editor-button[title="Underline"],
// .angular-editor-button[title="Superscript"],
// .angular-editor-button[title="Justify Left"],
// .angular-editor-button[title="Justify Right"],
// .angular-editor-button[title="Justify Full"],
// .angular-editor-button[title="Indent"],
// .angular-editor-button[title="Outdent"],
// .angular-editor-button[title="Unordered List"],
// .angular-editor-button[title="Ordered List"],
// .angular-editor-button[title="Underline"],
// .angular-editor-button[title="Text Color"],
// .angular-editor-button[title="Background Color"],
// .angular-editor-button[title="Insert Image"],
// .angular-editor-button[title="Insert Video"],
// .angular-editor-button[title="Horizontal Line"],
// .angular-editor-button[title="Strikethrough"],
// .select-heading,
// .select-font,
// .select-font-size,
// .select-custom-style {
//   display: none !important;
// }

// .jsoneditorContainer json-editor {
//   min-height: 225px !important;
// }

// .jsoneditorContainer fabric-button {
//   padding-left: 0px !important;
//   margin-top: 10px;
// }

// .jsoneditorContainer .jsoneditor-outer {
//   height: 300px !important;
// }

// .pageHeading {
//   font-size: 42px !important;
//   font-family: RobotoSlab !important;
//   color: #0071b9 !important;
//   padding: 20px 20px 0px;
//   text-align: center;
//   margin-top: 50px;
// }

// .pageSubHeading1 {
//   padding: 5px 90px 20px;
// }

// .pageSubHeading1,
// div.pageSubHeading1>* {
//   font-size: 18px !important;
//   font-family: RobotoLight !important;
//   color: #555 !important;
//   text-align: center;
//   line-height: 1.5;
//   word-break: break-word;
// }

// // @font-face {
// //   font-family: Roboto;
// //   // src: local("Roboto Regular"), url(./assets/Roboto/Roboto-Regular.ttf) format("truetype"),
// // }

// // @font-face {
// //   font-family: RobotoSlab;
// //   // src: local("Roboto Slab"), url(./assets/Roboto/RobotoSlab-Light.ttf) format("truetype"),
// // }

// // @font-face {
// //   font-family: RobotoLight;
// //   // src: local("Roboto Light"), url(./assets/Roboto/Roboto-Light.ttf) format("truetype"),
// // }

// .loginSection {
//   display: block;
//   text-align: center;
//   margin-top: 20px !important;
//   position: relative;
//   z-index: 1;
//   margin-bottom: 20px;
//   width: 100%;
// }

// .loginContainer {
//   text-align: center;
//   min-height: 400px;
//   margin-top: 60px;
// }

// .removeItem {
//   display: inline-block !important;
//   top: -10px;
// }

// .buttonContainer1 {
//   position: relative;
//   width: 350px;
//   margin: auto;
//   border: 1px solid #edf2fb;
//   padding-top: 15px;
//   padding-bottom: 20px;
//   border-radius: 3px;
//   padding-left: 20px;
//   padding-right: 20px;
// }

// .loginContainer .fabric-button.primary.raised.large.k-button {
//   background: #df195c !important;
//   border-radius: 3px;
//   border-radius: 2px solid #df195c !important;
//   width: 100% !important;
//   height: 30px;
// }

// .loginContainer .fabric-button.secondary.raised.large.k-button {
//   border: 2px solid #555 !important;
//   border-radius: 3px;
//   color: #555 !important;
//   background: none !important;
//   margin-top: 70px !important;
//   height: 30px;
// }

// .signUpContainer a {
//   margin-left: 10px;
//   font-size: 14px;
// }

// .loginSection .fabric-button {
//   text-transform: unset !important;
//   font-size: 16px;
// }

// .signUpContainer,
// .guestText {
//   margin-top: 10px !important;
//   width: 300px;
//   margin: auto;
// }

// .continueAsGuest {
//   margin-top: 60px !important;
// }

// .signUpContainer a,
// .signUpContainer span,
// .guestText {
//   font-size: 16px;
// }

// .signUpContainer a {
//   text-decoration: underline;
//   cursor: pointer;
// }

// .guestText {
//   color: #555;
//   font-family: RobotoLight !important;
// }

// .copyrightContainer {
//   height: 67px;
// }

// .copyText {
//   width: 1200px;
//   margin: auto !important;
//   font-size: 14px;
//   height: 37px;
//   padding-top: 10px;
//   top: 15px;
//   position: relative;
//   padding-left: 20px;
// }

// .bottomLinks li {
//   margin: 0px 10px 0px 0px !important;
// }

// .bottomLinks a {
//   color: #fff !important;
// }

// .bottomLinks {
//   text-align: right;
// }

// .footerForRest .bottomLinks li {
//   margin: 0px 10px 0px 0px !important;
//   list-style-type: none !important;
//   display: inline-block !important;
// }

// .footerForRest .bottomLinks li a {
//   color: #555 !important;
// }

// .footerForRest {
//   border-top: 1px solid #aebbd9;
//   // margin-top:50px;
// }

// .footerForRest .copyText {
//   color: #555 !important;
// }

// app-formly-preview .mat-expansion-panel:not([class*=mat-elevation-z]) {
//   box-shadow: none !important;
//   border: 1px solid #aebbd9;
// }

// app-formly-preview .mat-expansion-panel-header-title {
//   font-size: 18px;
//   font-weight: 500;
//   color: #0071b9;
// }

// app-formly-preview mat-select,
// app-formly-preview .mat-input-element {
//   height: 34px;
//   border: 1px solid #aebbd9;
//   border-radius: 4px;
//   color: #555;
// }

// app-formly-preview .mat-select-arrow {
//   margin-top: 12px;
// }

// app-formly-preview .mat-mdc-form-field-infix .mat-input-element,
// app-formly-preview .mat-select {
//   border-bottom: 1px solid #aebbd9 !important;
// }

// app-formly-preview .mat-mdc-form-field-label-wrapper {
//   top: -35px;
//   left: 8px;
// }

// app-formly-preview .mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float .mat-input-server:focus+.mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label,
// app-formly-preview .mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
//   transform: translateY(0px) scale(1) perspective(100px) translateZ(0px);
//   -ms-transform: translateY(0px) scale(1);
//   width: 133.33333%;
// }

// app-formly-preview .mat-select-value,
// app-formly-preview .mat-input-element {
//   padding-top: 8px;
//   padding-left: 8px;
// }

// app-formly-preview .mat-mdc-form-field.mat-focused .mat-mdc-form-field-label {
//   color: #000;
// }

// app-formly-preview .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
//   color: rgba(0, 0, 0, 1);
// }

// app-formly-preview .mat-radio-group {
//   margin-top: 8px;
//   display: block;
// }

// app-formly-preview .mat-radio-group mat-radio-button {
//   margin-right: 8px;
// }

// app-formly-preview .mat-radio-label-content {
//   padding-left: 3px;
//   padding-top: 2px;
// }

// app-formly-preview .mat-dialog-actions button,
// app-formly-preview .mat-dialog-actions button:focus,
// app-formly-preview .mat-dialog-actions button:hover {
//   margin-left: 15px;
//   border: 2px solid #df195c !important;
//   border-radius: 3px;
//   color: #fff !important;
//   background-color: #df195c !important;
// }

// app-formly-preview formly-field.col-md-12 formly-field-mat-checkbox {
//   display: block;
//   margin-top: -5px;
// }

// app-formly-preview .mat-expansion-panel {
//   margin-bottom: 20px;
// }

// app-formly-preview formly-field {
//   height: 90px;
// }

// app-formly-preview .mat-mdc-form-field-required-marker {
//   color: red !important;
// }

// kendo-grid-list colgroup col {
//   padding: 8px 20px;
// }

// // .dialogContMax {
// //   max-height:100vh !important;
// //   max-width:65vw !important;
// // }
// // .dialogContMin {
// //   max-height:100vh !important;
// //   max-width:65vw !important;
// // }

// // .cdk-overlay-pane {
// //   height:100vh !important;
// // }

// // .dialogContMin .dynamic-form-container {
// //   height: 275px !important;
// // }

// mat-dialog-container app-formly-preview .formPreviewIcons button {
//   background-color: #fff !important;
//   border: none !important;
// }

// mat-dialog-container app-formly-preview .formPreviewIcons button:focus {
//   outline: none !important;
// }

// .formPreviewIcons {
//   text-align: right;
//   margin-right: 10px;
//   margin-bottom: 20px;
//   border-bottom: 2px solid #000;
//   padding-bottom: 10px;
//   padding-top: 25px;
//   padding-left: 20px;
// }


// .formPreviewIcons .popupHeader {
//   display: inline-block;
//   float: left;
//   font-size: 18px;
//   font-weight: bold;
//   margin-top: 3px;
// }

// .k-window-title {
//   font-size: 18px;
//   font-weight: bold;
// }

// .k-window-titlebar {
//   margin-bottom: 20px;
//   border-bottom: 2px solid #000;
// }

// .formPreviewIcons i {
//   color: #0071b9;
// }

// .removeBtn {
//   position: absolute;
//   top: -45px;
//   right: -14%;
// }

// .addBtn {
//   position: absolute;
//   top: 5px;
//   left: 7px;
// }

// .addButton button,
// .removeButton button {
//   border: 2px solid #555 !important;
//   border-radius: 3px;
//   color: #555 !important;
//   background: none !important;
// }

// .removeButton {
//   display: inline-block !important;
//   left: 40%;
//   top: -45px;
// }

// .claimlistingdown .jsoneditor.jsoneditor-mode-code {
//   margin-top: -30px;
// }

// .claimlistingdown .k-window-titlebar {
//   border-bottom: 2px solid #000;
// }

// .claimlistingdown .k-window-title {
//   text-align: left;
// }

// .claimlistingdown .k-content.k-window-content .mat-mdc-form-field {
//   margin-top: 15px;
// }

// .claimlistingdown .jsoneditorContainer .fabric-window.k-widget.k-window {
//   height: 465px !important;
// }

// .fabric-confirmation-dialog .confirmationContent {
//   border-top: none !important;
//   border-bottom: 1px solid #888;
//   text-align: center;
//   padding: 5px !important;
// }

// .fabric-confirmation-dialog .actionButtons {
//   justify-content: center !important;
// }

// .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
//   opacity: 0.5 !important;
// }

// .cdk-overlay-dark-backdrop {
//   background: rgba(0, 0, 0, 1) !important;
// }

// .Language-label .fabric-checkbox label {
//   margin-bottom: 0px !important;
// }

// .capitalize {
//   text-transform: capitalize;
// }

// .disablechktext {
//   display: inline-block;
//   margin-left: 5px;
//   margin-top: -1px;
// }

// .chkbx {
//   margin-top: 33px;
// }

// .k-loading-pdf-mask {
//   display: none !important;
// }

// .fileerrormessage {
//   font-size: 12px;
//   position: absolute;
//   top: 44px;
// }

// .k-grid .k-grid-header .k-header .k-link {
//   height: auto;
// }

// .k-grid .k-grid-header .k-header {
//   white-space: normal;
// }

// .mat-stepper-horizontal {
//   margin: 10px 8px 25px 9px !important;
// }


// .mat-horizontal-stepper-header {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   text-align: center;
//   font-size: 0.9rem;
// }

// .mat-horizontal-stepper-header-container {
//   margin-bottom: 20px;
// }

// .mat-horizontal-stepper-header .mat-step-icon,
// .mat-horizontal-stepper-header .mat-step-icon-not-touched {
//   margin-right: 0 !important;
// }

// div.mat-step-label.mat-step-label-active.mat-step-label-selected {
//   color: #0071b9 !important;
// }

// .mat-horizontal-stepper-content {
//   width: 100% !important;
// }

// .mat-step-label {
//   position: relative;
//   top: 10px;
//   width: 100%;
//   font-size: 16px;
// }

// .mat-horizontal-content-container {
//   box-shadow: none !important;
//   border: 1px solid #efefef;
//   padding-top: 20px !important;
//   display: flex;
//   flex-wrap: wrap;
//   margin-right: -15px;
//   margin-left: -15px;
// }

// .labelTxt .dropdown-container .floating-label-dropdown {
//   left: 0px !important;
//   // margin-top: 0px !important;
//   line-height: 13px !important;
//   // top: 10px !important;
// }

// .confirmationTemplate {
//   .labelTxt .dropdown-container .floating-label-dropdown {
//     left: 7px !important;
//     // margin-top: 0px !important;
//     line-height: 13px !important;
//     top: 10px !important;

//   }
// }

// .angular-editor .angular-editor-wrapper .angular-editor-textarea {
//   font-weight: normal !important;
// }

// .defaultWindow {
//   position: absolute !important;
//   top: 0px !important;
// }

// .k-input-md .k-input-button,
// .k-input-md .k-spinner-increase,
// .k-input-md .k-spinner-decrease,
// .k-picker-md .k-input-button,
// .k-picker-md .k-spinner-increase,
// .k-picker-md .k-spinner-decrease {
//   padding-block: 12px;
//   padding-inline: 4px;
// }

// .k-picker-solid {
//   border-color: rgba(0, 0, 0, 0.38);
//   color: rgba(0, 0, 0, 0.87);
//   background-color: transparent !important;
// }

// .mdc-text-field--filled:not(.mdc-text-field--disabled) {
//   background-color: transparent !important;
// }

// .mdc-text-field--filled:not(.mdc-text-field--disabled) {
//   background-color: transparent !important;
//   padding-top: 14px !important;
//   width: 100%;
// }

// .k-tabstrip-items-wrapper .k-item:active,
// .k-tabstrip-items-wrapper .k-item.k-active,
// .k-tabstrip-items-wrapper .k-item.k-selected {
//   color: #c90b51 !important;
// }

// .k-tabstrip-items-wrapper .k-item.k-active::after {
//   border-color: #c90b51 !important;
// }

// .k-tabstrip-top>.k-tabstrip-items-wrapper .k-item::after {
//   border-bottom-width: 4px;
// }

// .k-table-thead,
// .k-table-header,
// .k-table-group-sticky-header {
//   color: #0059A6 !important;
//   border-spacing: 0 8px;
//   border-collapse: separate;
// }

// .k-grid .k-grid-filter,
// .k-grid .k-header-column-menu,
// .k-grid .k-grid-header-menu,
// .k-grid .k-hierarchy-cell .k-icon,
// .k-grid .k-hierarchy-cell .k-svg-icon {
//   color: #c90b51;
// }

// .k-grid-header-wrap,
// .k-grouping-header,
// .k-grouping-header .k-group-indicator,
// .k-header {
//   border-color: rgba(0, 0, 0, .12);
// }

// .k-grid .k-table-row {
//   border-color: inherit;
//   box-shadow: inset 0 0 3px rgba(0, 0, 0, .12);
// }

// .k-grid-aria-root table,
// .fabric-grid .k-grid-content table {
//   border-spacing: 0 8px;
// }

// .k-button-solid-primary {
//   color: #193971;
//   background-color: #fff;
//   border-color: transparent;
// }

// .k-actions .k-button-solid-base,
// .k-edit-buttons .k-button-solid-base,
// .k-action-buttons .k-button-solid-base,
// .k-columnmenu-actions .k-button-solid-base,
// .k-form-buttons .k-button-solid-base {
//   color: rgba(0, 0, 0, 0.87);

//   background-color: #fff;
//   border-color: #fff;
// }

// .k-checkbox:checked,
// .k-checkbox.k-checked {
//   border-color: #c90b51;
//   color: white;
//   background-color: #c90b51;
// }

// .k-pager-numbers .k-selected {
//   cursor: inherit;
//   z-index: 2;
//   background-color: #c90b51 !important;
//   color: #fff;
// }

// .k-rounded-md {
//   border-radius: 4px;
// }

// .k-toolbar .k-button-solid-base {
//   background-color: #fff;
//   border-color: #fff;
// }




// .k-button-md.k-icon-button {
//   padding: 8px;
//   box-shadow: none;
//   color: #005aa6;
// }

// button,
// select {
//   text-transform: none;
//   // color: #005aa6 !important;
// }

// .grid-btns {
//   color: #fff;
//   background-color: #193971 !important;
//   margin-right: 16px;
// }

// .toolbar-btns {
//   color: #fff;
//   background-color: #193971;
//   padding: 10px;
//   border-radius: 2px;
//   border-color: transparent;
// }

// .k-grid-pager {
//   color: rgba(0, 0, 0, 0.54);
// }

// .k-grid {
//   border-width: 1px;
//   border-style: solid;
//   box-sizing: border-box;
//   outline: 0;
//   font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
//   font-size: 14px;
//   line-height: 2;
//   display: flex;
//   flex-direction: column;
//   position: relative;
//   -webkit-touch-callout: none;
//   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
// }

// .k-grid .k-table {
//   border-spacing: 0 8px;
// }

// .k-column-resizer {
//   width: 0.5em;
//   height: 1000%;
//   display: block;
//   position: absolute;
//   top: 0;
//   inset-inline-end: 0;
//   cursor: col-resize;
//   border-right: 1px solid rgba(0, 0, 0, .12);
// }

// a:not([href]):not([tabindex]),
// a:not([href]):not([tabindex]):focus,
// a:not([href]):not([tabindex]):hover {
//   color: inherit;
//   text-decoration: none;
// }

// // .k-button-solid-base {
// //   box-shadow: var(--kendo-elevation-2, 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 3px 1px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14));
// //   color: #005aa6;
// //   background-color: #ffffff;
// //   border-color: #ffffff;
// // }
// .k-checkbox {
//   border-radius: 0px !important;
// }

// .k-button-flat-base {
//   color: inherit;
// }

// .fabric-button.normal.basic[_ngcontent-rwq-c15]:not(:disabled) {
//   color: #464646;
// }

// .fabric-button.primary.basic[_ngcontent-rwq-c15]:not(:disabled) {
//   color: #193971;
// }

// .k-pager-md .k-pager-sizes .k-dropdown-list,
// .k-pager-md .k-pager-sizes .k-dropdown,
// .k-pager-md .k-pager-sizes .k-dropdownlist,
// .k-pager-md .k-pager-sizes>select {
//   width: 4.5em !important;
//   border-top: 1px solid;
//   border-left: 1px solid;
//   border-right: 1px solid;
//   border-bottom: 1px solid;
//   border-color: rgba(0, 0, 0, 0.54);
//   border-top-color: rgba(0, 0, 0, 0.54) !important;
//   border-bottom-left-radius: 4px !important;
//   border-bottom-right-radius: 4px !important;
//   border-top-left-radius: 4px !important;
//   border-top-right-radius: 4px !important;



// }

// .k-dd {
//   border-top: 1px solid #ababab;
//   border-left: 1px solid #ababab;
//   border-right: 1px solid #ababab;
//   border-bottom: 1px solid #ababab;
//   border-bottom-left-radius: 4px !important;
//   border-bottom-right-radius: 4px !important;
//   border-top-left-radius: 4px !important;
//   border-top-right-radius: 4px !important;
// }

// .k-pager-sizes {
//   gap: 2ex !important;
// }

// .k-rounded-md {
//   border-radius: 0px;
// }

// .k-input-inner {
//   color: #204486 !important;
// }

// .k-input-solid {
//   background-color: transparent !important;
// }

// .k-grid-header .k-grid-filter.k-active,
// .k-grid-header .k-table-th-column-menu.k-active,
// .k-grid-header .k-grid-header-menu.k-active,
// .k-grid-header .k-hierarchy-cell .k-icon.k-active {
//   color: white;
//   background-color: #c90b51 !important;
// }

// .k-grid-header .k-grid-header-menu:hover {
//   // color:#c90b51 !important;
//   // background-color: transparent !important;
// }

// .k-grouping-header {
//   padding: 8px 8px;
//   border-width: 1px 1px 1px;
//   border-style: solid;
//   border-color: rgba(0, 0, 0, 0.12) !important;
//   // border-radius: 4px;
//   white-space: normal;
//   flex-shrink: 0;
//   display: flex;
//   position: relative;
//   align-content: center;
//   justify-content: flex-start;
//   align-items: center;
// }

// .k-grid .k-grid-aria-root {
//   border-color: rgba(0, 0, 0, .12);
//   display: flex;
//   flex-direction: column;
//   flex: 1 1 auto;
//   // width: 1122px !important;
//   overflow: hidden;
// }

// .k-grid {
//   border-color: transparent !important;
//   color: rgba(0, 0, 0, 0.87);
//   background-color: #ffffff;
// }

// .k-grid .k-grid-md .k-grouping-header,
// .k-grid-md .k-grouping-header {
//   padding-block: 10px;
//   padding-inline: 19px;
//   gap: 8px;
// }

// .k-calendar .k-calendar-view tbody th,
// .k-calendar .k-calendar-view .k-calendar-tbody .k-calendar-th {
//   padding-left: 16px;
//   padding-right: 16px;
//   height: 3em !important;
//   font: inherit;
//   font-weight: bold;
//   text-transform: none;
//   text-align: left;
//   // padding-bottom:3px  ;
// }

// // .k-calendar-nav-today .k-button {
// //   color: #d20e0e !important;
// // }
// .k-calendar-header .k-calendar-nav .k-calendar-nav-today {
//   display: flex;
//   align-items: center;
//   color: red !important;
//   flex-flow: row;
//   gap: 4px;
// }

// .k-calendar-td .k-selected .ng-star-inserted .k-state-selected {
//   background-color: blue !important;
// }

// .windowPopup {
//   kendo-window.fabric-window.defaultWindow.k-window.ng-star-inserted {
//     position: relative !important;
//     left: -50% !important;
//     top: -50% !important;
//     width: 650px !important;
//     height: 550px;

//     .jsonEditor {
//       min-width: 100% !important;
//       min-height: 340px !important;
//       float: left;

//       .jsoneditor {
//         height: 340px !important;
//       }
//     }

//     .k-resize-s,
//     .k-resize-e {
//       display: none !important;
//     }
//   }
// }

// .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
//   background-color: #c90b51 !important;
// }

// .k-grid-filter-popup {
//   min-width: 200px !important;
//   max-width: 320px !important;
// }

// .k-filter-menu.k-popup .k-filter-menu-container,
// .k-grid-filter-popup.k-popup .k-filter-menu-container {
//   min-width: 116px !important;
//   max-width: 200px !important;
// }

// .fabric-checkbox .k-form-field .k-checkbox:focus {
//   border: 2.5px solid #c90b51 !important;
// }

// .mdc-text-field {
//   border-top-left-radius: var(--mdc-shape-small, 4px);
//   border-top-right-radius: var(--mdc-shape-small, 4px);
//   border-bottom-right-radius: 0;
//   border-bottom-left-radius: 0;
//   display: inline-flex;
//   align-items: baseline;
//   padding: 0 16px;
//   position: relative;
//   box-sizing: border-box;
//   overflow: visible !important;
//   overflow-x: visible;
//   overflow-y: visible;
//   will-change: opacity, transform, color;
// }

// .mat-mdc-cell {
//   font-weight: 600 !important;
// }

// .angular-editor .angular-editor-wrapper .angular-editor-textarea {
//   font-weight: 400 !important;
// }

// .k-tabstrip-content,
// .k-tabstrip>.k-content {
//   overflow: visible !important;
// }

// .k-form-field-wrap .k-input-solid {
//   border-top-color: #fff !important;
// }

// kendo-combobox .k-svg-i-x {
//   margin-left: -90px !important;
//   margin-top: -26px !important;
// }

// // .row {
// //   display: flex;
// //   flex-wrap: wrap;
// //   margin-right: -15px;
// //   margin-left: -15px;
// //   margin-top: 35px;
// // }
// .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
// .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
//   height: auto;
//   width: 100%;
//   position: relative;
//   left: -13px;
// }

// .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
//   padding-top: 30px !important;
//   padding-bottom: 0px !important;
//   margin-bottom: 10px;
// }

// .k-list-item.k-focus {
//   background-color: #add8e6 !important;
// }

// .k-calendar .k-content .k-today,
// .k-calendar .k-calendar-content .k-today {
//   border-color: rgba(0, 0, 0, 0.15);
//   color: rgba(0, 0, 0, 0.87);
//   background-color: rgba(0, 0, 0, 0.04) !important;
// }

// .fabric-checkbox .k-form-field .k-checkbox:checked:disabled {
//   background-color: #c90b51 !important;
//   border-color: #c90b51 !important;
// }

// .fabric-textbox .k-textbox.readonly,
// .fabric-textbox .k-textbox:disabled {
//   color: #193971 !important;
//   border-color: #193971 !important;
//   opacity: 1 !important;
//   border-style: solid !important;
// }

// .fabric-textbox .k-textbox:not(.readonlyValue):disabled {
//   color: #193971 !important;
//   border-color: #193971 !important;
//   opacity: 1 !important;
//   border-style: solid;
//   border-bottom-style: solid !important;
// }

// .k-colorgradient {
//   border-color: rgba(0, 0, 0, 0.12);
//   color: rgba(0, 0, 0, 0.87);
//   background-color: #ffffff;
//   width: 400px;
// }

// .k-grid-header .k-grid-header-menu:focus {
//   // color: #c90b51 !important;
//   // background-color: #fff;
//   box-shadow: none;
// }

// .k-filter-menu-container kendo-grid-filter-menu-input-wrapper {
//   gap: 0px !important;
// }

// .mdc-text-field--filled:not(.mdc-text-field--disabled) {
//   background-color: transparent !important;
// }

// .mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay {
//   opacity: 0 !important
// }

// .mat-mdc-text-field-wrapper:active .mat-mdc-form-field-focus-overlay {
//   opacity: 0 !important
// }

// mat-card .mdc-button {
//   align-self: flex-start;
// }

// .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
//   opacity: 0 !important;
// }

// .k-column-title {
//   min-width: 0;
//   text-overflow: clip;
//   white-space: nowrap;
//   flex-shrink: 1;
//   overflow: hidden;
//   text-wrap: pretty;
// }

// kendo-popup .k-input-solid {
//   border-top-color: #fff !important;
// }

// .mat-mdc-form-field-error {
//   display: block;
//   margin-left: -16px !important;
// }

// .mdc-form-field>label {
//   margin-left: 0;
//   margin-right: auto;
//   padding-left: 4px;
//   padding-right: 0;
//   padding-top: 8px;
//   order: 0;
// }

// .fabric-checkbox .k-form-field{
//   display: inline-flex !important;
//   align-items: start !important;
//   flex-direction: row !important;
// }
// .mdc-text-field--disabled.mdc-text-field--filled {
//    background-color: #fff !important;
//    border-bottom: 1px solid #adadad;
//    padding-top: 14px;
// }
// .k-picker-solid:disabled, .k-picker-solid:disabled, .k-picker-solid.k-disabled {
//   border-color: rgba(0, 0, 0, 0.06);
//   color: rgba(0, 0, 0, 0.38);
//   background-color: #fafafa;
//   border-bottom: 1px solid #adadad;
// }
// .k-input:disabled, .k-input[disabled], .k-input.k-disabled, .k-picker:disabled, .k-picker[disabled], .k-picker.k-disabled {
//   opacity: .6;
//   filter: none;
// }
// .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
//   padding-top: 37px;
//   padding-bottom: 5px;
// }
// .k-grid-content .k-table-row:last-child > td, .k-grid-content .k-table-row:last-child > .k-table-td, .k-grid-content-locked .k-table-row:last-child > td, .k-grid-content-locked .k-table-row:last-child > .k-table-td {
//   border-bottom-width: 0;
//   // text-wrap: nowrap;
// }
// .k-tooltip .k-callout {
//   color: #193971;
//   position: bottom;
// }

// .k-tooltip{
//   background-color: #0059A6;
//   color: #fff;
//   position: bottom;

// }
// .fabric-address-autocomplete .k-textbox :focus-visible{
//   outline: 0px #fff !important;
// }
// .k-input-md .k-input-inner, .k-picker-md .k-input-inner {
//     padding-block: 8px;
//     padding-inline: 3px !important;
// }
// .k-button:disabled, .k-button[disabled], .k-button.k-disabled {
//   opacity: .6 !important;
//   filter: none;
// }
// .k-grid .k-grid-header .k-table {
//   margin-bottom: -9px;
// }
// .k-pager-info {
//   text-align: end;
//   justify-content: flex-end;
//   flex: 1 1 0%;
//   order: 9;
//   text-wrap: balance;
// }
// fabric-radio .k-radio:disabled + .k-radio-label {
//   color: rgba(0, 0, 0, 0.54);
//   outline: 0;
//   cursor: default;
//   opacity: .6;
//   -webkit-filter: grayscale(.1);
//   filter: grayscale(.1);
//   pointer-events: none;
//   box-shadow: none;
//   display: flex;
// }
// fabric-radio ng-untouched ng-pristine ng-valid{
//   display: flex;
// }
// .k-radio {
//   margin: 0;
//   padding: 0;
//   // clip: rect(0,0,0,0);
//   overflow: hidden;
//   position: absolute;
//   // opacity: 0;
//   -webkit-appearance: none;
//   pointer-events: none;
// }
// .k-radio-label {
//   padding-left: 30px;
//   width: 100%;
// }
// .k-radio-label {
//   margin: 0;
//   padding-left: 24px;
//   min-height: 57px;
//   line-height: 16px;
//   vertical-align: text-top;
//   display: inline-flex;
//   align-items: flex-start;
//   position: relative;
//   cursor: pointer;
// }
// .k-form-field-wrap{
// .k-radio + .k-label, .k-radio-wrap + .k-label, .k-radio + .k-radio-label, .k-radio-wrap + .k-radio-label {
//   display: inline;
//   margin-inline-start: 4px;
//   font-size: medium;
//   line-height: 11px;

// }
// }

// .fabric-radio{
//   .k-radio + .k-label, .k-radio-wrap + .k-label, .k-radio + .k-radio-label, .k-radio-wrap + .k-radio-label {
//     display: block;
//     margin-inline-start: 4px;

//   }
//   height: 35px !important;
// }
// .k-radio+.k-radio-label::before {
//   border-color: rgba(0,0,0,.54);
// }
// .k-radio-label::before {
//   border-radius: 50%;
// }
// .fabric-radio .k-radio:hover {
//   border: 2.5px solid rgba(0,0,0,.54)!important;
// }
// // .k-radio-label::before {
// //   content: "";
// //   width: 20px;
// //   height: 20px;
// //   border-width: 2px;
// //   border-style: solid;
// // }
// // .k-radio-label::before {
// //   font-size: 16px;
// //   font-family: WebComponentsIcons,monospace;
// //   box-sizing: border-box;
// //   text-align: center;
// //   display: inline-flex;
// //   align-items: center;
// //   justify-content: center;
// //   overflow: hidden;
// //   position: absolute;
// //   top: 0;
// //   left: 0;
// // }
// // .fabric-radio .k-radio:checked + .k-radio-label::after {
// //   background-color: #c90b51;
// // }
// // .k-radio-label::after {
// //   content: "";
// //   width: 10px;
// //   height: 10px;
// //   border-radius: 50%;
// //   position: absolute;
// //   top: 5px;
// //   left: 5px;
// //   transform: scale(0);
// // }
// .dropdown-container .floating-label-dropdown.float {
//   color: #193971;
//   opacity: 1;
//   z-index: 1;
//   font-size: 11px;
//   visibility: visible;
//   position: absolute;
//   transform: translateY(-15px);
//   transition: .01s ease-in-out;
//   display: block;
//   margin-top: 7px;
// }
// .k-window-content, .k-prompt-container {
//   padding-block: 10px;
//   padding-inline: 18px;
//   border-width: 0;
//   border-color: inherit;
//   color: inherit;
//   background: none;
//   outline: 0;
//   overflow: hidden;
//   position: relative;
//   flex: 1 1 auto;
// }
// mat-dialog-container .mat-mdc-dialog-surface{
//   overflow: visible;
// }
// // .mat-expansion-panel .mat-expansion-panel-content{
// //   overflow-y:  auto !important;
// // }

// .k-dialog{
//   width: 450px;
//   min-width: 250px;
// }
// kendo-dialog-actions .k-button{

//   color: #193971;
//   font-weight: 400 ;
// }
//  .k-dialog-actions{
//   padding-inline: 130px !important;
// }
// // .k-dialog-titlebar-actions .k-button-md.k-icon-button{
// //   display: none;
// // }

// kendo-dialog .k-window-titlebar{
//   .k-window-title{
//     font-size: 16px;
//     font-weight: 400;

//   }
//   border-bottom: 0;
//   padding-left: 170px;
//   margin-bottom: -15px;
// }

// .mat-form-field-appearance-fill .mat-form-field-flex {
//   background-color: rgba(0,0,0,.04);
// }
// .mat-form-field-appearance-fill .mat-form-field-flex {
//   border-radius: 4px 4px 0 0;
//   padding: 0.75em 0.75em
// em
// 0 0.75em;
// }
// .mdc-text-field--disabled .mdc-floating-label {
//   color: rgba(0, 0, 0, 0.54) !important;
//   font-weight: 600;
//   font-size: 16px;
// }
// .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-empty.mat-form-field-label {
//   display: block;
// }
// .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
//   transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(0.001px);
//   -ms-transform: translateY(-1.28125em) scale(.75);
//   width: 133.33333%;
// }

// .mat-input-element:disabled {
//   color: rgba(0,0,0,.38);
// }
// .mat-input-element {
//   font: inherit;
//   background: 0 0;
//   color: currentColor;
//   border: none;
//   outline: 0;
//   padding: 0;
//   margin: 0;
//   width: 100%;
//   max-width: 100%;
//   vertical-align: bottom;
//   text-align: inherit;
// }
// input.k-input:disabled, input.k-input[disabled], textarea.k-textarea:disabled, textarea.k-textarea[disabled] {
//   outline: none;
//   cursor: default;
//   opacity: 1;
//   filter: grayscale(0.1);
//   pointer-events: none;
//   box-shadow: none;
// }
// .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
//   // left: -13px !important;
//   right: auto;
// }
// .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control:disabled {
//   height: auto;
//  position:  relative !important;
//   left: -12px;
// }
// textarea.k-textarea {
//   padding-block: 2px;
//   padding-inline: 4px;
// }
// input.k-input, textarea.k-textarea {
//   padding-block: 8px;
//   padding-inline: 2px;
// }


// .k-upload .k-dropzone {
//     display: flex;
//     align-items: center;
//     position: relative;
//     border-width: 0;
//     background-color: transparent;
// }
//  ::after, ::before {
//     box-sizing: border-box;
// }

// .k-state-disabled, .k-widget[disabled] {
//     outline: 0;
//     cursor: default;
//     opacity: .6;
//     -webkit-filter: grayscale(.1);
//     filter: grayscale(.1);
//     pointer-events: none;
//     box-shadow: none;
// }
// kendo-window .k-window-content{
//   overflow-y:  auto !important;
// }

// kendo-popup .k-dropdownlist-popup  kendo-list{
//   height: 250px !important;

// }
// .fabric-list .k-dropdownlist{
//   border:1px solid rgba(0,0,0,.12) !important;
//   border-radius: 1px !important;
// }
//   .mat-expansion-panel-content {
//   font: 600 12px/20px Roboto,"Helvetica Neue",sans-serif;
// }
// .mat-form-field {
//   display: inline-block;
//   position: relative;
//   text-align: left;
// }
// .mat-form-field {
//   font-size: inherit;
//   font-weight: 400;
//   line-height: 1.125;
//   font-family: Roboto,"Helvetica Neue",sans-serif;
// }
// .mat-form-field-appearance-legacy .mat-form-field-wrapper {
//   padding-bottom: 1.25em;
// }
// .mat-form-field-wrapper {
//   position: relative;
// }
// .mat-form-field-flex {
//   display: inline-flex;
//   align-items: baseline;
//   box-sizing: border-box;
//   width: 100%;
// }
// .mat-form-field-infix {
//   font-weight: 600;
// }

// .mat-input-element:disabled {
//   color: rgba(0,0,0,.38);
// }
// input.mat-input-element {
//   margin-top: -0.0625em;
// }
// .mat-input-element {
//   font: inherit;
//   background: 0 0;
//   color: currentColor;
//   border: none;
//   outline: 0;
//   padding: 0;
//   margin: 0;
//   width: 100%;
//   max-width: 100%;
//   vertical-align: bottom;
//   text-align: inherit;
// }
// .mat-form-field-label-wrapper {
//   position: absolute;
//   left: 0;
//   box-sizing: content-box;
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
//   pointer-events: none;
// }
// .mat-form-field-label-wrapper {
//   top: -0.84375em;
//   padding-top: 0.84375em;
// }
// .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label, .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
//   transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(0.001px);
//   -ms-transform: translateY(-1.28125em) scale(.75);
//   width: 133.33333%;
// }
// .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-empty.mat-form-field-label {
//   display: block;
// }
// .mat-form-field-appearance-legacy .mat-form-field-label, .mat-form-field-appearance-legacy .mat-hint {
//   color: rgba(0,0,0,.54);
// }
// .mat-form-field-appearance-legacy .mat-form-field-label {
//   top: 1.28125em;
// }
// .mat-form-field-subscript-wrapper {
//   position: relative!important;
//   margin-bottom: 20px;
// }
// .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
//   margin-top: 0.54167em;
//   top: calc(100% - 1.66667em);
// }
// .mat-form-field-hint-spacer {
//   flex: 1 0 1em;
// }
// .mat-form-field-appearance-legacy .mat-form-field-ripple {
//   top: 0;
//   height: 2px;
//   overflow: hidden;
// }
// .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
//   background-position: 0;
//   background-color: transparent;
// }
// .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
//   background-image: linear-gradient(to right,rgba(0,0,0,.42) 0,rgba(0,0,0,.42) 33%,transparent 0);
//   background-size: 4px 100%;
//   background-repeat: repeat-x;
// }
// .mat-form-field-appearance-legacy .mat-form-field-underline {
//   height: 1px;
// }
// .mat-form-field-appearance-legacy .mat-form-field-underline {
//   background-color: #adadad!important;
// }
// .mat-form-field-appearance-legacy .mat-form-field-underline {
//   bottom: 1.25em;
// }
// .mat-form-field-underline {
//   position: absolute;
//   width: 100%;
//   pointer-events: none;
//   transform: scaleY(1.0001);
// }
// .mat-form-field-underline {
//   display: none;
// }
// .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
//   left: auto;
//   right: auto;
//   padding-top: 2px;
// }
// .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
//   top: 19px;
// }
// .mdc-floating-label {
//   position: relative;
//   left: 0;
//   -webkit-transform-origin: left top;
//   transform-origin: left top;
//   line-height: 1.15rem;
//   text-align: left;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   cursor: text;
//   overflow: hidden;
//   will-change: transform;
// }
// label {
//   display: inline-block;
//   margin-bottom: 0.5rem;
//   // padding-top: 17px;
// }
// .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
//   top: 19px;
//   left: -13px !important;
// }
// .k-form-field .k-label{
//   padding-top: 17px ;
// }
// .k-checkbox + .k-label, .k-checkbox-wrap + .k-label, .k-checkbox + .k-checkbox-label, .k-checkbox-wrap + .k-checkbox-label {
//   display: inline;
//   margin-inline-start: 4px;
//   padding-top: 0px !important;
// }
// .k-button {
//   border-radius: 2px;
//   padding: 8px 16px;
//   box-sizing: border-box;
//   border-width: 0;
//   border-style: solid;
//   font-size: 14px;
//   line-height: 1.4285714286;
//   font-family: inherit;
//   text-align: center;
//   text-decoration: none;
//   white-space: nowrap;
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   vertical-align: middle;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   cursor: pointer;
//   outline: 0;
//   -webkit-appearance: none;
//   position: relative;
//   text-transform: uppercase;
//   box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
//   font-weight: 500;
//   transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
//   font-family: SegoeUI,Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
// }
// .k-column-chooser-title, .k-column-list-item{
//   padding: 10px 0px 5px 19px;
// }
// .k-column-list-item .k-checkbox-label{
//   line-height: 22px;
// }
// .formBuildDrp{
// padding-top: 35px !important;
// }
// .mat-form-field-infix .mat-input-element, .mat-select {
//   border-bottom: 1px solid #adadad;
//   padding-bottom: 7px;
// }
// // .dropdown-container .k-dropdownlist  .readonly {
// //   .k-input-button {
// //     visibility: hidden !important;
// //   }

// // }
// .dropdown-container kendo-dropdownlist.readonly .k-button {
//   visibility: hidden!important;
// }
// .mat-form-field-appearance-legacy .mat-form-field-infix {
//   padding: 0.4375em 0;
// }
// .k-input-solid:disabled, .k-input-solid[disabled], .k-input-solid.k-disabled {
//   border-color: rgba(0, 0, 0, 0.06);
//   color: rgba(0, 0, 0, 0.38);
//   border-bottom: 1px solid #adadad;
// }
// .k-dropdownlist .k-disabled {
//   .k-input-inner {
//   color: rgba(0, 0, 0, 0.45) !important;
// }
//  }
//  .mat-expansion-panel {
//   background: #fff;
//   color: rgba(0, 0, 0, 0.54);
// }
// .k-form-field-wrap{
//   .k-input .k-numerictextbox  .k-input-solid[disabled], .k-input-solid.k-disabled  {
//     bottom: 14px;
//   }
// }
// .jsonWindow .mat-mdc-floating-label{
//   padding-top: 15PX  !important;
// }
// .k-form-field-wrap .k-input-value-text{
//   color: rgba(0, 0, 0, .87);
// }
// .k-radio-list .k-radio{
//   margin-top: 7px !important;
// }
// div.jsoneditor-outer {
//   position: static;
//   width: 100%;
//   height: 400px !important;
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }
// .k-chip-actions .k-chip-remove-action:first-child {
//   // margin-inline-start: 4px;
//   display: none;
// }